var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "components_popup_select_users" }, [
    _c(
      "div",
      {
        staticClass: "mask d-flex align-center justify-center",
        style: { zIndex: _vm.index },
      },
      [
        _c("div", { staticClass: "wrapper bbox d-flex flex-column" }, [
          _c("div", { staticClass: "title_group" }, [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _c("img", {
              staticClass: "close cp",
              attrs: {
                src: require("@/assets/images/common/close.png"),
                alt: "",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.doClose.apply(null, arguments)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "search_group" }, [
            _c(
              "div",
              { staticClass: "search_box" },
              [
                _c("img", {
                  staticClass: "search_icon cp",
                  attrs: {
                    src: require("@/assets/images/common/search_icon.png"),
                    alt: "",
                  },
                }),
                _c("el-input", {
                  staticClass: "search",
                  attrs: { placeholder: "搜索" },
                  model: {
                    value: _vm.input,
                    callback: function ($$v) {
                      _vm.input = $$v
                    },
                    expression: "input",
                  },
                }),
                _c("img", {
                  staticClass: "clear_icon cp",
                  attrs: {
                    src: require("@/assets/images/common/clear_icon.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.input = ""
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "container_group" }, [
            _c(
              "div",
              { staticClass: "select_group un_sel" },
              [
                _vm.select_all
                  ? _c(
                      "div",
                      {
                        staticClass: "select_all_group cp",
                        class: { on: _vm.is_all },
                        on: { click: _vm.doSelectAll },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "text d-flex align-center" },
                          [
                            _c("div", { staticClass: "flex-shrink-0" }, [
                              _vm._v("全选"),
                            ]),
                            [
                              _c("div", [_vm._v("（")]),
                              _vm.loading
                                ? _c("loading-icon", {
                                    attrs: { size: "12px" },
                                  })
                                : _vm._e(),
                            ],
                            _c("div", {}, [
                              _vm._v(
                                _vm._s(_vm.loading ? "" : _vm.list.length) +
                                  "）"
                              ),
                            ]),
                          ],
                          2
                        ),
                        _vm._m(0),
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.list, function (item, index) {
                  return [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !item.hide &&
                              ((_vm.input && item.searchName) || !_vm.input),
                            expression:
                              "!item.hide &&  ((input && item.searchName) || !input) ",
                          },
                        ],
                        key: `index${index}`,
                        staticClass: "unit padding",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "create_dialog_select_unit cp",
                            class: { on: item.selected },
                            on: {
                              click: function ($event) {
                                return _vm.selectItem(item)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "name_group" }, [
                              _c("img", {
                                staticClass: "avatar",
                                attrs: {
                                  src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                                  alt: "",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "name",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      item.searchName ||
                                        item.friendRemark ||
                                        item.userName
                                    ),
                                  },
                                },
                                [_vm._v(_vm._s(item.userName))]
                              ),
                            ]),
                            _vm._m(1, true),
                          ]
                        ),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "selected_group un_sel" },
              [
                _vm._l(_vm.selected_list, function (item, index) {
                  return [
                    _c("div", { key: index, staticClass: "unit padding" }, [
                      _c(
                        "div",
                        { staticClass: "create_dialog_selected_unit" },
                        [
                          _c("div", { staticClass: "name_group" }, [
                            _c("img", {
                              staticClass: "avatar",
                              attrs: {
                                src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                                alt: "",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "name",
                                domProps: {
                                  innerHTML: _vm._s(
                                    item.friendRemark || item.userName
                                  ),
                                },
                              },
                              [_vm._v(_vm._s(item.userName))]
                            ),
                          ]),
                          item.deletable
                            ? _c("div", { staticClass: "select_box" }, [
                                _c("div", {
                                  staticClass: "select cp",
                                  on: {
                                    click: function ($event) {
                                      return _vm.doDelete(item)
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "fun_group" }, [
            _c(
              "button",
              {
                staticClass: "btn btn_dark sure",
                on: {
                  click: function ($event) {
                    return _vm.doSure()
                  },
                },
              },
              [_vm._v("确定")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn_light cancel",
                on: {
                  click: function ($event) {
                    return _vm.doClose()
                  },
                },
              },
              [_vm._v("取消")]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "select_box" }, [
      _c("div", { staticClass: "select cp" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "select_box" }, [
      _c("div", { staticClass: "select cp" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }